import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { Alert, AlertType } from '../interfaces/alert.interface';
import { AlertActions } from '../store/actions/alert.action';

function createGenericErrorAlert({
  error,
}: {
  error: HttpErrorResponse;
}): Alert {
  return {
    id: uuidv4(),
    message:
      error?.error?.error?.message ??
      (error?.error?.message || 'An error occurred'),
    errors: Object.entries(error?.error?.error?.errors || {}),
    type: 'error',
  };
}

export function handleHttpError(error: { error: HttpErrorResponse }): Action {
  return AlertActions.create({ alert: createGenericErrorAlert(error) });
}

export function createCustomAlert(opts?: {
  message?: string;
  errors?: [string, unknown][] | undefined;
  type?: AlertType;
}): Action {
  return AlertActions.create({
    alert: {
      id: uuidv4(),
      message: opts?.message || 'Something went wrong. Please try again later',
      errors: Object.entries(opts?.errors || {}),
      type: opts?.type || 'error',
    },
  });
}
